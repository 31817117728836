import {Dictionary} from '../types/types'

export function encode(
  provider: string,
  format: string,
  params: Dictionary<string>,
) {
  provider = encodeURIComponent(provider)
  format = encodeURIComponent(format)
  params = Object.assign({}, params)
  const b64 = btoa(JSON.stringify(params))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
  const encoded = ['razr://', provider, '/', format, '?', b64].join('')
  return encoded
}
